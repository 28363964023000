import { isPuppeteer } from '@spa-core-js/util/extractStyles'
import logUrl from '@spa-core-js/services/logSvc/request'
import deriveArea from '@spa-core-js/util/deriveArea'
import cartEntriesToItem from '@spa-core-js/util/cartEntriesToItem'
import { GTM_TRACKING_QUEUE_KEY, GoogleAnalyticsCategory, TrackingActionTypes } from '../constants'
import { getReducer } from '@spa-core/legacy-adapter/utils'
import { NAME as appReducerName } from '@spa-core/store/app/constants'
import { SessionConfig } from '@spa-core/store/app/interfaces'
import { CookiebotUtil } from '../utils/CookiebotUtil'
import { addToTrackingQueue, getTrackingQueue } from '../utils'
import logger from '@spa-core-js/services/logSvc'

const log: any = logger.getLogger('tag-manager-middleware')

export const trackFetchedOrderConfirmationData = (action): void => {
    if (!action.data.affiliateReportData) return
    const items = []
    // No point of delaying these as they are sent at once anyway
    action.data.entries.forEach((entry) => {
        const reportEntry = action.data.affiliateReportData.orderEntryValues[entry.product.code]

        const item = {
            item_id: entry.product.code, // SKU/code.
            item_name: entry.product.name, // Product name. Required.
            item_category: entry.product.googleAnalyticsCategoryName, // Category or variation.
            price: reportEntry.prodpriceexcvat, // Unit price in local currency to GTM. GA4 will recalculate automatically
            quantity: reportEntry.prodcount, // Quantity.
            is_enkrona: entry.enKronaProduct,
            discount: entry.enKronaProduct ? reportEntry.prodpriceexcvat : reportEntry.proddiscountexcvat, // Discount.(full price - discounted price)
            // in local currency to GTM. GA4 will recalculate automatically
        }
        items.push(item)
    })

    // Calculating ordersumma inc VAT in SEK
    // const deliveryCostExVat = parseFloat(action.data.deliveryCostExVat.replace(',', '.'));
    const deliveryCostExVat = parseFloat(action.data.deliveryCostExVatPrice.value)
    const subTotalExVat = parseFloat(action.data.subTotalExVatPrice.value)

    // IC-17178 Add hashed data for Enhanced Conversion setup

    const sha256_email_address = action.data.affiliateReportData.orderValues.emailsha256

    const sha256_phone_number = action.data.affiliateReportData.orderValues.phonesha256

    const sessionConfig: any = {}

    pushTracking({
        event: 'purchase',
        transaction_id: action.data.code, // Transaction ID. Required.
        shipping: deliveryCostExVat, // Shipping.
        tax: action.data.totalTax.value, // Tax.
        b2bStore: sessionConfig.b2bMode,
        affiliation: sessionConfig.campaignNumber ? sessionConfig.campaignNumber : action.data.store, // Affiliation or store name.
        value: subTotalExVat,
        currency: action.data.affiliateReportData.orderValues.cur, // Currency.
        paymentmethod: action.data.paymentModeType, // Payment method type, for example "creditcard", "qliro_invoice"
        shippingmethod: action.data.deliveryMode.deliveryModeType, // Shipping method type, for example "Postal_package", "Letter"
        items,
        sha256_email_address,
        sha256_phone_number,
        eventCallback: () => {
            logUrl('GTM_HIT_CALLBACK', 'addTransaction')
        },
    })
    if (sessionConfig.isSPA) {
        pushTracking({
            event: 'SPA_ORDER',
            event_category: deriveArea(),
            event_action: 'SPA_ORDER',
            event_label: action.data.code,
            eventCallback: () => {
                logUrl('GA4_HIT_CALLBACK', 'SPA_ORDER')
            },
        })
    }
    pushTracking({
        event: 'ACCEPTED_PAYMENT_OPTION',
        event_category: deriveArea(),
        event_action: 'ACCEPTED_PAYMENT_OPTION',
        event_label: action.data.paymentModeCode,
    })
    pushTracking({
        event: 'ACCEPTED_SHIPMENT_METHOD',
        hit_type: 'event',
        event_category: deriveArea(),
        event_action: 'ACCEPTED_SHIPMENT_METHOD',
        event_label: action.data.deliveryMode.code,
    })
}

let spaSessionSent = false
let pCount
let cCount
let mCount

const sendTrackingQueue = () => {
    log.info('sendTrackingQueue has consent', cookiebotUtil.hasStatisticsConsent)
    if (cookiebotUtil.hasStatisticsConsent) {
        const trackingQueue: any[] = getTrackingQueue()
        log.info('sendTrackingQueue', trackingQueue)
        trackingQueue.forEach((trackingObject) => window['dataLayer']?.push(trackingObject))
        sessionStorage.setItem(GTM_TRACKING_QUEUE_KEY, undefined)
    }
}

const cookiebotUtil: CookiebotUtil = new CookiebotUtil(sendTrackingQueue)

const pushTracking = (trackingObject: any) => {
    if (cookiebotUtil.cookiebotIsLoaded && cookiebotUtil.hasStatisticsConsent) {
        log.info('pushTracking - window[dataLayer]', window?.['dataLayer'])
        window?.['dataLayer']?.push(trackingObject)
    } else {
        addToTrackingQueue(trackingObject)
    }
}

const tagManagerMiddleware = (store) => (next) => (action) => {
    if (!store) {
        return
    }

    if (!cookiebotUtil.hasStatisticsConsent && cookiebotUtil.cookiebotIsLoaded) {
        next(action)
        return
    }

    const sessionConfig: SessionConfig = getReducer(appReducerName)?.sessionConfig

    if (!sessionConfig?.isSPA) {
        next(action)
        return
    }

    if (!spaSessionSent && !isPuppeteer && typeof window !== 'undefined' && window['dataLayer']) {
        spaSessionSent = true
        /* eslint-disable*/
        pushTracking({
            'event': 'SESSION',
            'event_category': 'SESSION',
            'event_action': 'SPA_SESSION',
            'event_label': 'SPA_SESSION',
            'non_interaction': true,
        });
        /* eslint-enable */
    }

    if (action) {
        const { type, payload } = action
        switch (action.type) {
            case TrackingActionTypes.PAGE_VIEW:
                pushTracking({
                    event: 'spa_page_view',
                    page_location: payload.path,
                    // page_referer - previous page
                    page_referer: payload.location.pathname,
                    event_category: deriveArea(),
                })
                // Data is coming in anyway, not required it seems
                break
            case TrackingActionTypes.CART_UPDATED:
                {
                    const trigger = payload.buyTrackingTriggerName || ''
                    const data: any = {
                        event: 'cart_updated',
                        trigger,
                        currency: payload.currency,
                        value: payload.value ? payload.value : payload.price * payload.qty,
                        is_enkrona: payload.is_enkrona,
                        event_category: deriveArea(),
                        items: [
                            {
                                item_id: payload.productCode,
                                item_name: payload.productName,
                                price: payload.price,
                                quantity: payload.qty,
                                discount: payload.discount,
                            },
                        ],
                    }
                    if (data.is_enkrona) {
                        data.discount = 0
                    }
                    pushTracking(data)
                }
                break
            case TrackingActionTypes.CART_PRODUCT_QUANTITY_DECREASE:
            case TrackingActionTypes.CART_PRODUCT_QUANTITY_INCREASE:
                {
                    const event: string =
                        type === TrackingActionTypes.CART_PRODUCT_QUANTITY_DECREASE ? 'remove_from_cart_gtm' : 'add_to_cart'
                    const data: any = {
                        event,
                        ...payload,
                    }
                    if (data.is_enkrona) {
                        data.discount = 0
                    }
                    pushTracking(data)
                }
                break
            case TrackingActionTypes.ADD_PAYMENT_INFO:
                pushTracking({
                    event: 'add_payment_info',
                    currency: payload.currency,
                    value: payload.value,
                    method: payload.method,
                    event_category: 'CART',
                    items: payload.items,
                })
                break
            case TrackingActionTypes.ADD_SHIPPING_INFO:
                pushTracking({
                    event: 'add_shipping_info',
                    currency: payload.currency,
                    value: payload.value,
                    method: payload.method,
                    event_category: 'CART',
                    items: payload.items,
                })
                break
            case TrackingActionTypes.BEGIN_CHECKOUT:
                pushTracking({
                    event: 'begin_checkout',
                    currency: payload.currency,
                    value: payload.value,
                    b2bStore: sessionConfig.b2bMode,
                    items: cartEntriesToItem(payload.entries),
                })
                break
            case TrackingActionTypes.VIEW_CART_PAGE:
                pushTracking({
                    event: 'view_cart',
                    currency: payload.currency,
                    value: payload.value,
                    b2bStore: sessionConfig.b2bMode,
                    items: cartEntriesToItem(payload.entries),
                })
                break
            case TrackingActionTypes.FETCHED_CATEGORIES:
                pushTracking({
                    event: 'view_item_list',
                    item_list_id: payload.itemListId,
                    item_list_name: payload.itemListName,
                    items: (payload.items || []).slice(0, 10),
                })
                break
            case TrackingActionTypes.VIEWED_PRODUCT:
                pushTracking({
                    event: 'view_item',
                    currency: payload.currency,
                    value: payload.value,
                    discount: payload.discount,
                    items: payload.items,
                })
                break
            case TrackingActionTypes.REPLACE_PRODUCT_WITH_OWN_BRAND:
                pushTracking({
                    event: 'emv_replacement',
                    entryNumber: payload.entryNumber,
                    correspondsTo: payload.correspondsTo,
                    quantity: payload.quantity,
                    productCode: payload.productCode,
                    model: payload.model,
                })
                break
            case TrackingActionTypes.REPLACE_ALL_PRODUCTS_WITH_OWN_BRAND:
                pushTracking({
                    event: 'emv_replacement_all',
                    cart: payload.cart,
                    recommendedCart: payload.recommendedCart,
                })
                break
            case TrackingActionTypes.CANCEL_REPLACE_PRODUCT_WITH_OWN_BRAND:
                pushTracking({
                    event: 'emv_replacement_cancel',
                    entryNumber: payload.entryNumber,
                    correspondsTo: payload.correspondsTo,
                    quantity: payload.quantity,
                    productCode: payload.productCode,
                    model: payload.model,
                })
                break
            case TrackingActionTypes.VALIDATED_CHECKOUT_INPUT_FIELD:
                if (payload.valid === false) {
                    pushTracking({
                        event: 'ADDRESS_INPUT_ERROR',
                        eventCategory: deriveArea(),
                        eventAction: 'ADDRESS_INPUT_ERROR',
                        eventLabel: payload.key,
                    })
                }
                break
            case TrackingActionTypes.SEND_PLACE_ORDER:
                pushTracking({
                    event: 'PREFERRED_PAYMENT_OPTION',
                    eventCategory: deriveArea(),
                    eventAction: 'PREFERRED_PAYMENT_OPTION',
                    eventLabel: payload.paymentOption,
                })
                pushTracking({
                    event: 'PREFERRED_SHIPMENT_METHOD',
                    eventCategory: deriveArea(),
                    eventAction: 'PREFERRED_SHIPMENT_METHOD',
                    eventLabel: payload.shipmentMethod,
                })
                break
            case TrackingActionTypes.FETCHED_ORDER_CONFIRMATION_DATA:
                trackFetchedOrderConfirmationData(payload)
                break
            case TrackingActionTypes.CAMPAIGN_ACTIVATED:
                pushTracking({
                    event: 'activate_campaign',
                    promotion_id: payload.id,
                    creative_name: payload.utm_source,
                    creative_slot: payload.utm_content,
                    campaign_id: payload.campaignNumber,
                })
                break
            case TrackingActionTypes.PROMOTIONS_UPDATED: {
                /**
                 * TODO: Don't send duplicates, fix logic in trackAppliedPromotions
                 */
                const uniquePromotionCodes = payload.promotionCodes?.filter(
                    (value, index, array) => array.indexOf(value) === index,
                )
                pushTracking({
                    event: 'activate_promotion',
                    promotion_codes: uniquePromotionCodes,
                })
                break
            }
            case TrackingActionTypes.SEARCH_RESULTS:
                pCount = payload?.searchResult?.productSearchResult?.pagination?.totalNumberOfResults
                    ? payload.searchResult.productSearchResult.pagination.totalNumberOfResults
                    : 0
                cCount = payload?.searchResult?.categories?.length || 0
                mCount = 0
                if (payload?.searchResult?.modelSearchResult?.[0]) {
                    payload.searchResult.modelSearchResult.forEach((modelSearchResult) => {
                        mCount += modelSearchResult.totalNumberOfResults
                    })
                }
                pushTracking({
                    event: 'search',
                    eventLabel: 'FULLPAGE',
                    searchTerm: payload.gaLabel,
                    code: pCount + cCount + mCount,
                    productCount: pCount,
                    categoryCount: cCount,
                    modelCount: mCount,
                })
                break
            case TrackingActionTypes.AUTOCOMPLETE_SEARCH_RESULTS:
                pCount = payload?.result?.products?.length || 0
                cCount = payload?.result?.categories?.length || 0
                pushTracking({
                    event: 'search',
                    eventLabel: 'DROPDOWN',
                    searchTerm: payload.gaLabel,
                    code: pCount + cCount,
                    productCount: pCount,
                    categoryCount: cCount,
                    modelCount: 0,
                })
                break
            case TrackingActionTypes.HEADER_SEARCH_RESULT_BOX_CLOSED:
                pushTracking({
                    event: 'search',
                    searchTerm: payload.gaLabel,
                    code: payload.gaVal,
                })
                break
            case TrackingActionTypes.SESSION_CONFIG_UPDATED:
                pushTracking({
                    event: payload.gaType,
                    eventCategory: payload.gaCat || GoogleAnalyticsCategory.OTHER,
                    eventAction: payload.gaType,
                    eventLabel: payload.gaLabel,
                    nonInteraction: true,
                })
                break
            case TrackingActionTypes.OPEN_FILTER:
                pushTracking({
                    event: 'filter',
                    filterId: payload.groupId,
                    label: payload.label.split('#')[0],
                })
                break
            case TrackingActionTypes.ACTIVATE_FILTER:
                pushTracking({
                    event: 'filter',
                    filterId: payload.id,
                    label: window.location.href.split('#')[0],
                })
                break
            default:
        }
    }
    next(action)
}

export default tagManagerMiddleware
