import siteTheme from '@ic-site-theme';
import * as mixins from '@spa-core-js/mixins';
import { merge } from 'ts-deepmerge';
import defaultTheme from './default-theme';
import componentOverrides from '@ic-site-theme-overrides';
import legacyComponentOverrides from '@ic-site-theme-overrides-legacy';
const mergedTheme = merge.withOptions({ mergeArrays: false }, defaultTheme, siteTheme);
export default mergedTheme;
/**
 * @deprecated Will be removed, migrate to getComponentTheme
 */
export const getTheme = (componentName, componentProps = undefined) => {
    var _a;
    const overrides = (componentOverrides === null || componentOverrides === void 0 ? void 0 : componentOverrides[componentName]) || ((_a = legacyComponentOverrides === null || legacyComponentOverrides === void 0 ? void 0 : legacyComponentOverrides[componentName]) === null || _a === void 0 ? void 0 : _a.call(legacyComponentOverrides, mergedTheme)) || {};
    return merge.withOptions({ mergeArrays: false }, mergedTheme, componentProps || {}, overrides);
};
const themeFactoryGenerator = (componentName, componentProps) => {
    var _a;
    const overrides = (componentOverrides === null || componentOverrides === void 0 ? void 0 : componentOverrides[componentName]) || ((_a = legacyComponentOverrides[componentName]) === null || _a === void 0 ? void 0 : _a.call(legacyComponentOverrides, mergedTheme)) || {};
    const generatedTheme = merge.withOptions({ mergeArrays: false }, siteTheme, componentProps || {}, overrides, mergedTheme);
    mixins.setTheme(generatedTheme);
    return () => generatedTheme;
};
/**
 * @deprecated Will be removed, migrate to getComponentTheme
 */
export const getThemeFactory = (componentName, componentProps) => themeFactoryGenerator(componentName, componentProps);
export const getComponentTheme = (componentName, componentProps = undefined) => themeFactoryGenerator(componentName, componentProps)();
