import theme from '@ic-theme'
import logger from '@spa-core-js/services/logSvc'
import net from '@spa-core-js/services/networkSvc'
import { HttpMethods } from '@spa-core/constants/HttpMethods'
import { addMessages, addRestCallFailed } from '@spa-core/store/global-messages/actions'
import { str } from '@spa-ec-js/services/localeSvc'
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects'
import { GoogleAnalyticsCategory, MatomoLevel, TrackingActionTypes } from '../../tracking/constants'
import { TrackCartUpdatedPayload, TrackFetchedCartPayload } from '../../tracking/interfaces'
import { updateSessionConfig } from '../app/actions'
import { SessionConfig } from '../app/interfaces'
import { MessageLevels } from '../global-messages/constants'
import { AddMessage, RestCallFailed } from '../global-messages/interfaces'
import { ActionTypes as ProductActionTypes } from '../products/constants'
import { ActionTypes as AppActionTypes } from '../app/constants'
import { selectSessionConfig, trackAppliedPromotions } from '../utils'
import { setCartDataInStore } from './actions'
import { ActionTypes, NAME as cartReducerName } from './constants'
import {
    AddSubscriptionToCartPayload,
    CartDataResponse,
    CartEntry,
    CartResponse,
    FetchCartPayload,
    FetchOrderConfirmationPayload,
    HandleAbandonedCartPayload,
    OrderConfirmation,
    Recommended,
    SubscriptionResponse,
    UpdateCartEntryPayload,
} from './interfaces'
import { parseCartEntries } from './utils'

const log = logger.getLogger('Cart')

export function* addToCart({ payload }: any) {
    const { productCode, quantity, modelCategoryCode, currentlyAddingKey, buyTrackingTriggerName, isEnKrona = false } = payload

    const updatingCart: boolean = yield select((state) => state?.reducers?.[cartReducerName]?.updatingCart)
    if (updatingCart) return

    yield put({
        type: ActionTypes.SET_UPDATING_CART,
        payload: {
            updatingCart: true,
        },
    })

    const sessionConfig: SessionConfig = yield select(selectSessionConfig)

    yield put({
        type: ActionTypes.SET_CURRENTLY_ADDING_TO_CART,
        payload: {
            productCode: currentlyAddingKey || productCode,
            value: true,
        },
    })

    const addToCartPayload: string = `quantity=${quantity}&productCode=${productCode}&enKrona=${isEnKrona}${
        modelCategoryCode ? '&modelCategoryCode=' + modelCategoryCode : ''
    }`
    const addToCartUrl: string = `${sessionConfig.urlPrefix}/rest/${sessionConfig.enableV2Cart ? 'v2' : 'v1'}/cart`

    try {
        const result: CartResponse = yield call(() => net.post(addToCartUrl, addToCartPayload))

        if (result?.errors?.length) {
            const errorMessages: AddMessage[] = []
            result.errors.forEach((error) => {
                errorMessages.push({
                    title: str(error.message + '.title'),
                    message: str(error.message + '.body'),
                    level: MessageLevels.ERROR,
                    id: error.message,
                    displaySeconds: 6,
                })
            })
            yield put(
                addMessages({
                    messages: errorMessages,
                }),
            )
        }

        if (result.data) {
            yield put(updateSessionConfig())
            const fallbackImage: string = `${sessionConfig.themeResourcePath}/${theme.placeholderImageSrc}`
            const cartData: CartDataResponse = {
                ...result.data,
                entries: parseCartEntries(result.data.entries, fallbackImage),
            }

            yield trackAppliedPromotions(cartData?.appliedOrderPromotions, cartData?.appliedProductPromotions)

            yield put(
                setCartDataInStore({
                    cartData,
                }),
            )

            if (result?.data?.entries?.length > 0) {
                const filteredEntries = result.data.entries.filter((e) => e.product.code === productCode)
                if (filteredEntries.length > 0) {
                    const filterEntry = filteredEntries[0]
                    const buyPrice = filterEntry.buyPrice
                    const basePrice = filterEntry.basePrice
                    const value = buyPrice.value * quantity
                    const discount = basePrice.value - buyPrice.value
                    const currency = basePrice.currencyIso
                    const filteredProd = filterEntry.product

                    const trackingPayload: TrackCartUpdatedPayload = {
                        price: basePrice.value,
                        qty: quantity,
                        currency,
                        value,
                        productCode,
                        productName: filteredProd.name,
                        is_enkrona: isEnKrona,
                        discount,
                        buyTrackingTriggerName,
                    }
                    yield put({
                        type: TrackingActionTypes.CART_UPDATED,
                        payload: trackingPayload,
                    })
                    yield put({
                        type: ProductActionTypes.FETCH_CROSS_SELL_PRODUCT_CODES,
                    })
                }
            }
        }

        yield put({
            type: ActionTypes.SET_CURRENTLY_ADDING_TO_CART,
            payload: {
                productCode: currentlyAddingKey || productCode,
                value: false,
            },
        })

        yield put({
            type: AppActionTypes.FETCH_PROMOTIONS,
        })

        const trackingPayload: TrackFetchedCartPayload = {
            gaCat: GoogleAnalyticsCategory.CART,
            cartData: result.data,
            productCode,
            matomoLevel: MatomoLevel.MEDIUM,
        }
        yield put({
            type: TrackingActionTypes.FETCHED_CART,
            payload: trackingPayload,
        })

        yield put({
            type: ActionTypes.SET_UPDATING_CART,
            payload: {
                updatingCart: false,
            },
        })

        yield put({
            type: ActionTypes.ITEM_ADDED,
        })
    } catch (error) {
        yield put(
            addRestCallFailed({
                method: HttpMethods.POST,
                url: addToCartUrl,
                error,
            }),
        )
    }
}

export function* fetchCart({ payload }: any = {}) {
    const { info, callback }: FetchCartPayload = payload || {}
    yield put({
        type: ActionTypes.SET_UPDATING_CART,
        payload: {
            updatingCart: true,
        },
    })

    let infoQs: string = ''
    if (info) {
        infoQs = `?info=${info}&recalculate=true`
    }

    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/${sessionConfig.enableV2Cart ? 'v2' : 'v1'}/cart${infoQs}`

    try {
        const fallbackImage: string = `${sessionConfig.themeResourcePath}/${theme.placeholderImageSrc}`
        const cartDataResponse: CartDataResponse = yield call(() => net.get(url))
        const cartData: CartDataResponse = {
            ...cartDataResponse,
            entries: parseCartEntries(cartDataResponse.entries, fallbackImage),
        }

        yield trackAppliedPromotions(cartData?.appliedOrderPromotions, cartData?.appliedProductPromotions)

        yield put(
            setCartDataInStore({
                cartData,
            }),
        )

        const trackingPayload: TrackFetchedCartPayload = {
            gaCat: GoogleAnalyticsCategory.CART,
            cartData: cartDataResponse,
            matomoLevel: MatomoLevel.MEDIUM,
        }
        yield put({
            type: TrackingActionTypes.FETCHED_CART,
            payload: trackingPayload,
        })

        yield put({
            type: ActionTypes.SET_UPDATING_CART,
            payload: {
                updatingCart: false,
            },
        })

        /**
         * TODO: Remove when old implementations of cart is refactored
         */
        if (callback) {
            callback(cartData)
        }
    } catch (err) {
        yield put(
            addRestCallFailed({
                url,
                method: HttpMethods.GET,
                requestError: err,
            }),
        )
    }
}

export function* addSubscriptionProductToCart({ payload }: any) {
    const { productCode, modelCategoryCode, callback }: AddSubscriptionToCartPayload = payload

    yield put({
        type: ActionTypes.SET_UPDATING_CART,
        payload: {
            updatingCart: true,
        },
    })

    yield put({
        type: ActionTypes.SET_CURRENTLY_ADDING_TO_CART,
        payload: {
            productCode,
            value: true,
        },
    })

    const postData: string = `type=subscription&qty=1&subscriptionProductCode=${productCode}${
        modelCategoryCode ? '&modelCategoryCode=' + modelCategoryCode : ''
    }`
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/${sessionConfig.enableV2Cart ? 'v2' : 'v1'}/cart/add-subscription`

    try {
        const result: SubscriptionResponse = yield call(() => net.post(url, postData))
        if (!result.errorMsg && callback) {
            callback()
        }
        /**
         * Tracking
         */
        if (result?.modificationResponseDto?.success && sessionConfig.enableV2Cart) {
            if (sessionConfig.enableV2Cart) {
                const fallbackImage: string = `${sessionConfig.themeResourcePath}/${theme.placeholderImageSrc}`
                const cartData: CartDataResponse = {
                    ...result.modificationResponseDto.data,
                    entries: parseCartEntries(result.modificationResponseDto.data.entries, fallbackImage),
                }

                yield trackAppliedPromotions(cartData?.appliedOrderPromotions, cartData?.appliedProductPromotions)

                yield put(
                    setCartDataInStore({
                        cartData,
                    }),
                )

                const trackingPayload: TrackFetchedCartPayload = {
                    gaCat: GoogleAnalyticsCategory.CART,
                    cartData: result?.modificationResponseDto?.data,
                    matomoLevel: MatomoLevel.MEDIUM,
                }
                yield put({
                    type: TrackingActionTypes.FETCHED_CART,
                    payload: trackingPayload,
                })

                if (result?.modificationResponseDto?.data?.entries?.length > 0) {
                    const entries: CartEntry[] = result.modificationResponseDto.data.entries.filter(
                        (e) => e.product.code === productCode,
                    )
                    if (entries.length > 0) {
                        const entry: CartEntry = entries[0]
                        const trackingPayload: TrackCartUpdatedPayload = {
                            price: entry.basePrice.value,
                            qty: 1,
                            currency: entry.basePrice.currencyIso,
                            value: entry.buyPrice.value,
                            productCode,
                            productName: entry.product.name,
                            is_enkrona: false,
                            discount: entry.basePrice.value - entry.buyPrice.value,
                        }
                        yield put({
                            type: TrackingActionTypes.CART_UPDATED,
                            payload: trackingPayload,
                        })
                    }
                }
            } else {
                yield put({
                    type: ActionTypes.FETCH_CART,
                })
            }
        }

        yield put({
            type: AppActionTypes.FETCH_PROMOTIONS,
        })

        yield put({
            type: ActionTypes.SET_UPDATING_CART,
            payload: {
                updatingCart: false,
            },
        })
    } catch (error) {
        yield put(
            addRestCallFailed({
                url,
                method: HttpMethods.POST,
                error,
            }),
        )
    }

    yield put({
        type: ActionTypes.SET_CURRENTLY_ADDING_TO_CART,
        payload: {
            productCode,
            value: false,
        },
    })
}

export function* updateCartEntry({ payload }: any) {
    const { quantity, entryNumber }: UpdateCartEntryPayload = payload

    const updatingCart: boolean = yield select((state) => state?.reducers?.[cartReducerName]?.updatingCart)
    if (updatingCart) return

    yield put({
        type: ActionTypes.SET_UPDATING_CART,
        payload: {
            updatingCart: true,
        },
    })

    const body: string = `quantity=${quantity}&entryNumber=${entryNumber}`
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/${sessionConfig.enableV2Cart ? 'v2' : 'v1'}/cart`

    try {
        const result: CartResponse = yield call(() => net.put(url, body))
        const entry: CartEntry = yield select((state) => state?.reducers?.[cartReducerName]?.entries[entryNumber])
        const fallbackImage: string = `${sessionConfig.themeResourcePath}/${theme.placeholderImageSrc}`
        const cartData: CartDataResponse = {
            ...result.data,
            entries: parseCartEntries(result.data.entries, fallbackImage),
        }

        /**
         * Tracking
         */
        yield trackAppliedPromotions(cartData?.appliedOrderPromotions, cartData?.appliedProductPromotions)

        yield put(
            setCartDataInStore({
                cartData,
            }),
        )

        const productPrice: number = entry.basePrice ? entry.basePrice.value : entry.product.price.value
        const discountedPrice: number = entry?.buyPrice?.value !== entry?.basePrice?.value ? entry?.buyPrice?.value : 0
        const discount: number = discountedPrice > 0 ? productPrice - discountedPrice : 0

        const fetchedCartTrackingPayload: TrackFetchedCartPayload = {
            gaCat: GoogleAnalyticsCategory.CART,
            cartData: result.data,
            matomoLevel: MatomoLevel.MEDIUM,
        }
        yield put({
            type: TrackingActionTypes.FETCHED_CART,
            payload: fetchedCartTrackingPayload,
        })

        const cartUpdatedTrackingPayload: TrackCartUpdatedPayload = {
            qty: quantity,
            discount,
            price: productPrice,
            value: (productPrice - discount) * quantity,
            productCode: entry.product.code,
            productName: entry.product.name,
            is_enkrona: entry.enKronaProduct,
            currency: entry.buyPrice.currencyIso,
        }
        yield put({
            type: TrackingActionTypes.CART_UPDATED,
            payload: cartUpdatedTrackingPayload,
        })

        yield put({
            type:
                entry.quantity > quantity
                    ? TrackingActionTypes.CART_PRODUCT_QUANTITY_DECREASE
                    : TrackingActionTypes.CART_PRODUCT_QUANTITY_INCREASE,
            payload: cartUpdatedTrackingPayload,
        })
        /**
         * TODO: Why?
         */
        if (quantity === 0) {
            yield put(updateSessionConfig())
        }
        yield put({
            type: AppActionTypes.FETCH_PROMOTIONS,
        })
        yield put({
            type: ActionTypes.SET_UPDATING_CART,
            payload: {
                updatingCart: false,
            },
        })
        yield put({
            type: ProductActionTypes.FETCH_CROSS_SELL_PRODUCT_CODES,
        })
    } catch (err) {
        const payload: RestCallFailed = {
            url,
            method: HttpMethods.PUT,
            requestError: err,
        }
        yield put(addRestCallFailed(payload))
    }
}

export function* fetchRecommendedCart() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/cart/recommended`
    try {
        const recommended: Recommended = yield call(() => net.get(url))
        const fallbackImage: string = `${sessionConfig.themeResourcePath}/${theme.conf.placeholderImageSrc}`
        if (recommended?.recommendedCart?.entries?.length > 0) {
            recommended.recommendedCart.entries = parseCartEntries(recommended?.recommendedCart?.entries, fallbackImage)
        }

        yield put({
            type: ActionTypes.FETCHED_RECOMMENDED_CART,
            payload: recommended,
        })
    } catch (err) {
        log.error('Error in fetching the cart recommendations', err)
    }
}

export function* restoreAbandonedCart() {
    yield put({
        type: ActionTypes.SET_RESTORING_ABANDONED_CART,
        payload: {
            restoringAbandonedCart: true,
        },
    })
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/cart/abandoned/restore`
    try {
        yield call(() => net.get(url))
    } catch (err) {
        log.error('Error in restoring the abandoned cart', err)
    }
    yield fetchCart()
    yield put({
        type: ActionTypes.SET_RESTORING_ABANDONED_CART,
        payload: {
            restoringAbandonedCart: false,
        },
    })
    yield put({
        type: ActionTypes.SET_SHOW_RESTORE_CART,
        payload: {
            showRestoreCart: false,
        },
    })
}

export function* rejectAbandonedCartSaga() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/cart/abandoned/delete`
    try {
        yield call(() => net.get(url))
    } catch (err) {
        log.error('Error in deleting the abandoned cart', err)
    }
    yield put({
        type: ActionTypes.SET_SHOW_RESTORE_CART,
        payload: {
            showRestoreCart: false,
        },
    })
}

const shouldRestoreCartSilently = (cartData: any, defaultTimeInSec: number): boolean => {
    const modifiedTime: number = cartData.modifiedTime
    const currentTime: number = new Date().getTime()
    const timeDifference: number = currentTime - modifiedTime
    const timeDifferenceInSec: number = timeDifference / 1000
    return timeDifferenceInSec <= defaultTimeInSec
}

export function* handleAbandonedCart({ payload }: any) {
    const { abandonedCartData }: HandleAbandonedCartPayload = payload
    if (abandonedCartData !== null) {
        const restoreCartSilently: boolean = shouldRestoreCartSilently(
            abandonedCartData.cartData,
            abandonedCartData.defaultRestorationTime,
        )
        if (restoreCartSilently) {
            yield restoreAbandonedCart()
        } else if (!abandonedCartData.cartData || abandonedCartData.cartData === 'false') {
            yield put({
                type: ActionTypes.SET_SHOW_RESTORE_CART,
                payload: {
                    showRestoreCart: false,
                },
            })
        } else {
            yield put({
                type: ActionTypes.SET_RESTORE_CART_TOTAL_ITEMS,
                payload: {
                    totalItems: abandonedCartData?.cartData?.totalUnitCount || 0,
                },
            })
            yield put({
                type: ActionTypes.SET_SHOW_RESTORE_CART,
                payload: {
                    showRestoreCart: true,
                },
            })
        }
    }
}

export function* fetchOrderConfirmation({ payload }: any) {
    const { orderId }: FetchOrderConfirmationPayload = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url = sessionConfig.urlPrefix + `/rest/v1/checkout/order/${orderId}`
    try {
        const orderConfirmation: OrderConfirmation = yield call(() => net.get(url))
        const fallbackImage: string = `${sessionConfig.themeResourcePath}/${theme.placeholderImageSrc}`
        yield put({
            type: ActionTypes.FETCHED_ORDER_CONFIRMATION,
            payload: {
                orderConfirmation: {
                    ...orderConfirmation,
                    entries: parseCartEntries(orderConfirmation.entries, fallbackImage),
                },
            },
        })
        yield put({
            type: TrackingActionTypes.FETCHED_ORDER_CONFIRMATION_DATA,
            payload: {
                data: orderConfirmation,
                upsellCompleted: false,
            },
        })
    } catch (error) {
        yield put(
            addRestCallFailed({
                url,
                method: HttpMethods.GET,
                error,
            }),
        )
    }
}

export function* resetSubscriptionInterval() {
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/cart/subscription/interval`
    try {
        const fallbackImage: string = `${sessionConfig.themeResourcePath}/${theme.conf.placeholderImageSrc}`
        const result: CartResponse = yield call(() => net.delete(url))
        const cartData: CartDataResponse = {
            ...result.data,
            entries: parseCartEntries(result.data.entries, fallbackImage),
        }

        yield trackAppliedPromotions(cartData?.appliedOrderPromotions, cartData?.appliedProductPromotions)

        yield put(
            setCartDataInStore({
                cartData,
            }),
        )
        const isSubscriptionEligible: boolean = yield select(
            (state) => state?.reducers?.[cartReducerName]?.isSubscriptionEligible,
        )
        const trackingPayload: TrackFetchedCartPayload = {
            gaCat: GoogleAnalyticsCategory.CART,
            cartData: result.data,
            enableSubscriptionMode: true,
            isSubscriptionEligible,
            matomoLevel: MatomoLevel.MEDIUM,
        }
        yield put({
            type: TrackingActionTypes.FETCHED_CART,
            payload: trackingPayload,
        })
    } catch (error) {
        yield put(
            addRestCallFailed({
                url,
                method: HttpMethods.DELETE,
                error,
            }),
        )
    }
}

export function* setSubscriptionInterval({ payload }: any) {
    const { subscriptionIntervalCode } = payload
    const sessionConfig: SessionConfig = yield select(selectSessionConfig)
    const url: string = `${sessionConfig.urlPrefix}/rest/v1/cart/subscription/interval/${subscriptionIntervalCode}`
    try {
        const fallbackImage: string = `${sessionConfig.themeResourcePath}/${theme.conf.placeholderImageSrc}`
        const result: CartResponse = yield call(() => net.put(url))
        const cartData: CartDataResponse = {
            ...result.data,
            entries: parseCartEntries(result.data.entries, fallbackImage),
        }

        yield trackAppliedPromotions(cartData?.appliedOrderPromotions, cartData?.appliedProductPromotions)

        yield put(
            setCartDataInStore({
                cartData,
            }),
        )
        const isSubscriptionEligible: boolean = yield select(
            (state) => state?.reducers?.[cartReducerName]?.isSubscriptionEligible,
        )
        const trackingPayload: TrackFetchedCartPayload = {
            gaCat: GoogleAnalyticsCategory.CART,
            cartData: result.data,
            enableSubscriptionMode: true,
            isSubscriptionEligible,
            matomoLevel: MatomoLevel.MEDIUM,
        }
        yield put({
            type: TrackingActionTypes.FETCHED_CART,
            payload: trackingPayload,
        })
    } catch (error) {
        yield put(
            addRestCallFailed({
                url,
                method: HttpMethods.DELETE,
                error,
            }),
        )
    }
}

export const watchers = [
    takeLeading(ActionTypes.ADD_TO_CART, addToCart),
    takeLeading(ActionTypes.UPDATE_CART_ENTRY, updateCartEntry),
    takeLatest(ActionTypes.FETCH_CART, fetchCart),
    takeLatest(ActionTypes.FETCH_RECOMMENDED_CART, fetchRecommendedCart),
    takeLatest(ActionTypes.RESTORE_ABANDONED_CART, restoreAbandonedCart),
    takeLatest(ActionTypes.HANDLE_ABANDONED_CART, handleAbandonedCart),
    takeLatest(ActionTypes.REJECT_ABANDONED_CART, rejectAbandonedCartSaga),
    takeLatest(ActionTypes.ADD_SUBSCRIPTION_PRODUCT_TO_CART, addSubscriptionProductToCart),
    takeLatest(ActionTypes.FETCH_ORDER_CONFIRMATION, fetchOrderConfirmation),
    takeLatest(ActionTypes.RESET_SUBSCRIPTION_INTERVAL, resetSubscriptionInterval),
    takeLatest(ActionTypes.SET_SUBSCRIPTION_INTERVAL, setSubscriptionInterval),
]
