const themeGen = (theme) => {
  const res = {
    panelWidth: ' md:w-3/4 lg:w-full',
    panelPaddings: 'p-4',
    panelMargins: ' mt-4 mx-2 md:mx-4',
    panelFlexClasses: ' flex-col lg:flex-row',
    iconHeight: '100%',
  };
  return res;
};

export default themeGen;
