import cloneDeep from 'fast-clone'
import isEqual from 'lodash/isEqual'
import { Action } from '../interfaces'
import { ActionTypes } from './constants'
import { Product, ProductsStore, ReplacementProduct, SlimProduct, SubscriptionOption } from './interfaces'

export const INITIAL_STATE: ProductsStore = {
    loadingStatuses: {},
    products: {},
    slimProducts: {},
    productRelations: [],
    lastUpdate: new Date('1970-01-01').getTime(),
    replacementProducts: {},
    subscriptionOptions: {},
    selectedSubscriptionOptionIds: {},
    freeDeliverySubscriptionProducts: {},
    stockMonitored: {},
    stockMonitorSubscribeEmail: '',
    stockMonitorSubscribeError: '',
    crossSellProducts: [],
    promotions: {
        appliedPromotions: [],
        possiblePromotions: [],
    },
    fetchingProducts: false,
    testFreakReviews: {},
    openComparePopup: false,
    repurchasebleProducts: [],
}

const populatingProducts = (state) => (payload) => {
    const loadedProducts: Product[] = payload.products
    const products = cloneDeep(state.products)
    let newProducts: boolean = false
    loadedProducts.forEach((product: Product) => {
        /**
         * TODO:
         * Remove when adding typecheking for incoming data in sagas
         */
        const invalidCorrespondsTo: boolean = product?.correspondsTo?.split(',')?.length > 1
        if (invalidCorrespondsTo && products[product.code]) {
            products[product.code].correspondsTo = ''
        } else if (!isEqual(products[product.code], product)) {
            products[product.code] = product
            newProducts = true
        }
    })
    if (newProducts) {
        return {
            ...state,
            products,
            lastUpdate: new Date().getTime(),
        }
    }
    return state
}

export const reducer = (state = INITIAL_STATE, { payload, type }: Action): ProductsStore => {
    switch (type) {
        case ActionTypes.UPDATE_PRODUCT_LOADING_STATUS:
            return {
                ...state,
                loadingStatuses: {
                    ...state.loadingStatuses,
                    [payload.productCode]: payload.loadingStatus,
                },
            }
        case ActionTypes.FETCHED_PRODUCT_RELATIONS:
            return {
                ...state,
                productRelations: payload.productRelations,
            }
        case ActionTypes.FETCHED_PROMOTIONS: {
            const { promotions } = payload
            return {
                ...state,
                promotions: {
                    appliedPromotions: promotions.appliedPromotions || [],
                    possiblePromotions: promotions.possiblePromotions || [],
                },
            }
        }
        case ActionTypes.FETCHED_PRODUCT_REVIEW_BY_CODE: {
            const { productCode, testFreaksReview } = payload
            return {
                ...state,
                testFreakReviews: {
                    ...state.testFreakReviews,
                    [productCode]: {
                        reviewCount: testFreaksReview.your_review_count || '',
                        averageRating: testFreaksReview.your_avg_score || '',
                    },
                },
            }
        }
        case ActionTypes.FETCHED_PRODUCTS: {
            return populatingProducts(state)(payload)
        }
        case ActionTypes.FETCHED_SLIM_PRODUCTS: {
            const loadedSlimProducts: SlimProduct[] = payload.slimProducts
            const slimProducts = cloneDeep(state.slimProducts)
            let newProducts: boolean = false
            loadedSlimProducts.forEach((slimProduct: SlimProduct) => {
                if (!isEqual(slimProducts[slimProduct.code], slimProduct)) {
                    slimProducts[slimProduct.code] = slimProduct
                    newProducts = true
                }
            })
            if (newProducts) {
                return {
                    ...state,
                    slimProducts,
                    lastUpdate: new Date().getTime(),
                }
            }
            return state
        }
        case ActionTypes.FETCHED_REPLACEMENT_PRODUCTS: {
            if (!payload.result) return state
            const productCode: string = payload.productCode
            const replacementProduct: ReplacementProduct = payload.result
            replacementProduct.lastUpdate = new Date().getTime()
            return {
                ...state,
                replacementProducts: {
                    ...state.replacementProducts,
                    [productCode]: replacementProduct,
                },
            }
        }
        case ActionTypes.FETCHED_SUBSCRIPTION_OPTIONS: {
            if (!payload.subscriptionOptions) return state
            const productCode: string = payload.productCode
            const subscriptionOption: SubscriptionOption = payload.subscriptionOptions
            return {
                ...state,
                subscriptionOptions: {
                    ...state.subscriptionOptions,
                    [productCode]: {
                        ...subscriptionOption,
                        lastUpdate: new Date().getTime(),
                    },
                },
                selectedSubscriptionOptionIds: {
                    ...state.selectedSubscriptionOptionIds,
                    [productCode]:
                        subscriptionOption.defaultSubscriptionCode || subscriptionOption.subscriptionProductData?.[0].id,
                },
                freeDeliverySubscriptionProducts: {
                    ...state.freeDeliverySubscriptionProducts,
                    [productCode]: subscriptionOption.freeDeliverySubscriptionProduct || false,
                },
            }
        }
        case ActionTypes.SET_SELECTED_SUBSCRIPTION_OPTION_ID: {
            return {
                ...state,
                selectedSubscriptionOptionIds: {
                    ...state.selectedSubscriptionOptionIds,
                    [payload.productCode]: payload.subscriptionOptionId,
                },
            }
        }
        case ActionTypes.SET_STOCK_MONITORED: {
            return {
                ...state,
                stockMonitored: {
                    ...state.stockMonitored,
                    [payload.productCode]: payload.monitored,
                },
            }
        }
        case ActionTypes.SET_FETCHING_PRODUCTS: {
            return {
                ...state,
                fetchingProducts: payload.fetchingProducts,
            }
        }
        case ActionTypes.SET_STOCK_MONITOR_SUBSCRIBE_EMAIL: {
            return {
                ...state,
                stockMonitorSubscribeEmail: payload.email,
            }
        }
        case ActionTypes.SET_STOCK_MONITOR_SUBSCRIBE_ERROR: {
            return {
                ...state,
                stockMonitorSubscribeError: payload.error,
            }
        }
        case ActionTypes.FETCHED_CROSS_SELL_PRODUCT_CODES: {
            const { productCodes } = payload
            return {
                ...state,
                crossSellProducts: productCodes
                    .map((productCode: string) => state.slimProducts[productCode])
                    .filter((slimProduct: SlimProduct) => slimProduct),
            }
        }
        case ActionTypes.SET_COMPARE_POPUP_FLAG: {
            const { popupStatusValue } = payload
            return {
                ...state,
                openComparePopup: popupStatusValue,
            }
        }
        case ActionTypes.FETCHED_REPURCHASE_PRODUCT_DATA: {
            const { repurchasebleProducts } = payload
            return {
                ...state,
                repurchasebleProducts: Object.keys(repurchasebleProducts).map(function (index: any) {
                    const repurchasebleProductsArray = repurchasebleProducts[index]
                    return repurchasebleProductsArray
                }),
            }
        }
        default:
            return state
    }
}
